import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { compact, get, isEmpty, isEqual, slice } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { getChecklistDefinitionBindings } from 'redux/selectors/checklistItems/checklistItems';
import truncateString from 'core/utils/truncateString';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { RATING_MODE } from 'core/utils/constants';
import { tooltipSetter, searchMaxValue } from '../../utils';
import { WidgetCard } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';
import prepareData from './prepareData';

const ChecklistDefinitionQuestionsAverageScores = ({
  widgetData,
  loadingWidgetId,
  colors,
  isWarningWidget
}) => {
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const selectedChecklistDefinitionId = get(widgetData, 'filters.checklistDefinitionsIds', '');
  const currentChecklist = get(checklistDefinitionsByIds, selectedChecklistDefinitionId, {});
  const currentChecklistBindings = useSelector(state =>
    compact(getChecklistDefinitionBindings(state, currentChecklist))
  );
  const questionGroupsByIds = useSelector(state => state.questionGroupsResource.byIds);
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds);

  const widgetValue = Array.isArray(get(widgetsAnalyticsByIds, widgetData.id, []))
    ? get(widgetsAnalyticsByIds, widgetData.id, [])
    : Object.values(get(widgetsAnalyticsByIds, widgetData.id, []));

  const { id, type, filters, name } = widgetData;
  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});
  const preparedData =
    loadingWidgetId === id
      ? []
      : prepareData({
          widgetValue,
          currentChecklistBindings,
          questionGroupsByIds,
          checklistDefinition
        });

  const currentChecklistDefinition = Object.values(checklistDefinitionsByIds).find(
    checklistDefinition => checklistDefinition?.id === filters?.checklistDefinitionsIds
  );

  const getMaxValue = () => {
    return currentChecklistDefinition?.ratingMode === RATING_MODE.PERCENTAGE
      ? 100
      : currentChecklistDefinition.max;
  };

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={6} key={id}>
          <WidgetCard>
            <SMLWidgetHeader name={name} type={type} widgetData={widgetData} filters={filters} />

            <ResponsiveBar
              data={preparedData.length > 25 ? slice(preparedData, 0, 25) : preparedData}
              keys={['value', 'name']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              // maxValue={searchMaxValue({ checklistDefinitionsByIds, filters })}
              maxValue={getMaxValue()}
              tooltip={tooltip => tooltipSetter(tooltip.data)}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default ChecklistDefinitionQuestionsAverageScores;
